/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import XButton from 'vux/src/components/x-button';
import { Dialog } from "vant";
export default {
  components: {
    XButton: XButton
  },
  data: function data() {
    return {
      chooseName: [],
      //餐厅名字
      chooseList: [],
      //餐厅列表
      SerialStr: '',
      windowObj: '',
      //
      isShow: false,
      //是否显示订餐记录
      indexColor: 0,
      remark: '',
      alertFlag: false,
      userSerial: '',
      //人员序列
      dsGolist: false,
      //是否可以调整
      img: '',
      orderMeal: 0,
      //订餐方式
      startDate: '',
      endDate: ''
    };
  },
  methods: {
    getSysDate: function getSysDate() {
      var _this = this;

      this.$utils.Tools.loadShow();
      this.$api.Sys.getSystemDate(this.$params.mode).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);

          _this.finished = true;
        } else {
          _this.startDate = _this.$utils.DateUtils.getMonthStart(data.sysDate.substring(0, 7));
          _this.endDate = _this.$utils.DateUtils.getMonthEnd(data.sysDate.substring(0, 7));
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    alrtBtn: function alrtBtn() {
      if (this.windowObj == '' && this.windowObj.length == 0) return this.$utils.Tools.toastShow('未选择餐厅！');
      if (this.dsGolist == true) return this.$utils.Tools.toastShow('没有订餐权限哦！');
      this.$router.push({
        path: '/DateMealList',
        query: {
          placeId: this.windowObj,
          cancelFlag: '1'
        }
      });
    },
    getId: function getId(item, i) {
      this.windowObj = item.depSerial;
      this.indexColor = i;
    },
    userPermission: function userPermission() {
      var _this2 = this;

      this.$api.Meal.otderByUserSsion(this.userSerial).then(function (res) {
        if (res.data.orderFlag == 0) {
          _this2.dsGolist = true;

          _this2.$utils.Tools.toastShow('没有订餐权限哦~');
        }
      });
    },
    selectListByChoose: function selectListByChoose(params) {
      var _this3 = this;

      params = {
        depSerial: params
      };
      this.$api.AcDep.selectListByChoose(params).then(function (res) {
        // 获取全部数据
        // 全部数据获取成功后存进自己里
        _this3.chooseList = res.data;
        _this3.windowObj = _this3.chooseList[0].depSerial;
        _this3.orderMeal = _this3.chooseList[0].spareCol2;

        _this3.$utils.Store.setLocalItem('payType', 2);

        _this3.chooseList.forEach(function (item, i) {
          _this3.chooseName.push({
            key: res.data[i].depSerial,
            value: res.data[i].depName,
            type: res.data[i].payType,
            spareCol2: res.data[i].spareCol2
          });

          if (item.payType != 0) {
            _this3.$utils.Store.setLocalItem('payType', 3);
          }
        });

        if (_this3.chooseList.length == 1 && _this3.orderMeal != 1) {
          if (!_this3.dsGolist) {
            _this3.$router.replace({
              path: '/DateMealList',
              query: {
                placeId: _this3.windowObj,
                cancelFlag: '1'
              }
            });
          } else {
            _this3.$router.go(-1);
          }
        }
      });
    },

    /**
     * @function获取订餐说明弹窗
     *      wzx
     *   2020.0403
     **/
    showExplain: function showExplain(id) {
      var _this4 = this;

      this.$utils.Tools.loadShow();
      this.$api.Meal.getExplain(id).then(function (response) {
        _this4.$utils.Tools.loadHide();

        var code = response.code,
            data = response.data;

        if (+code === _this4.$code.success) {
          _this4.remark = data;
          _this4.img = data.spareCol2;
          var img = "<img style='width:100%'  src=".concat(data.spareCol2, ">");
          var Dsrc;

          if (data.spareCol2) {
            Dsrc = data.dineShow + '\n \n \n' + img;
          } else {
            Dsrc = data.dineShow + '\n \n \n';
          }

          Dialog.alert({
            title: '订餐说明',
            message: Dsrc,
            messageAlign: 'left'
          }).then(function () {// on close
          });
        }
      }).catch(function () {
        _this4.$utils.Tools.httpError();
      });
    }
  },
  created: function created() {
    this.getSysDate();
    this.userSerial = this.$utils.Store.getItem('userSerial');
    this.userPermission();
    this.selectListByChoose(10000);
  },
  mounted: function mounted() {}
};